@use '@angular/material' as mat;

$typography-configuration: mat.m2-define-typography-config($font-family: 'Roboto, Helvetica Neue, sans-serif;',
    $headline-1: mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
    $headline-2: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
    $headline-3: mat.m2-define-typography-level(26px, 14px, 400, $letter-spacing: -0.005em),
    $headline-4: mat.m2-define-typography-level(24px, 14px, 400),
    $headline-5: mat.m2-define-typography-level(22px, 14px, 400),
    /*h1*/
    $headline-6: mat.m2-define-typography-level(20px, 14px, 500),
    /* mat-dialog-title */
    $subtitle-1: mat.m2-define-typography-level(14px, 14px, 400),
    /*h3*/
    $subtitle-2: mat.m2-define-typography-level(14px, 14px, 400),
    /*h4*/
    $body-1: mat.m2-define-typography-level(14px, 14px, 400),
    $body-2: mat.m2-define-typography-level(14px, 14px, 400),
    $caption: mat.m2-define-typography-level(14px, 14px, 400),
    $button: mat.m2-define-typography-level(14px, 14px, 500));

$theme: mat.m2-define-light-theme(( // color: (primary: $theme-primary, accent: $theme-accent),
      typography: $typography-configuration,
    ));
@include mat.all-component-themes($theme);


.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: @apply font-sans;
  --mdc-chip-label-text-weight: 500;
}

.mat-card-header-text {
  line-height: 21px !important;
}
.mat-mdc-card-header-text {
  line-height: 21px !important;
  margin-left: 1rem;
  margin-right: 1rem;
}
.mat-mdc-card-title {
  --mat-card-title-text-line-height: 30px;
  --mat-card-subtitle-text-line-height: 21px;
}

.mat-mdc-card {
  --mat-card-subtitle-text-weight: 400;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.mat-mdc-input-element {
  box-shadow: none !important;
}

.sticky {
  position: sticky !important;
}


.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.mat-mdc-input-element {
  box-shadow: none !important;
}


.mdc-dialog__actions {
  padding: 20px !important;
}

.mat-mdc-tab-labels>.mdc-tab {
  min-width: 160px;
}

.mat-mdc-text-field-wrapper {
  flex: initial !important;
}

.mat-mdc-chip-grid {
  width: 100%;
}

.mat-mdc-tab-body-content {
  overflow-y: hidden !important;
}

.mdc-evolution-chip-set .mdc-evolution-chip {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mat-mdc-card-title {
  margin-bottom: 1rem !important;
}

.mat-mdc-card-subtitle {
  margin-bottom: 0.75rem !important;
}

mat-card-content {
  @apply p-4;
}

mat-dialog-content > form {
  padding-top: 0.5rem !important;
}
.mdc-dialog__title {
  padding: 8px 24px 16px !important;
}

.mat-mdc-card-outlined {
  border: 0 !important;
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f !important;
}

.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-mdc-form-field {
  @apply mb-2;
}

.mat-mdc-form-field-hint {
  color: #0009 !important;
}

.mat-mdc-form-field-infix {
  display: flex !important;
  align-items: center !important;
}

.mat-mdc-dialog-content .mdc-dialog__content {
  @apply my-4;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

/* Table */

table.mat-mdc-table > thead > tr > th {
  font-size: 12px !important;
  line-height: 18px !important;
}

table.mat-mdc-table > tbody > .mat-mdc-row {
  height: 48px;
  line-height: 20px !important;
}

.mat-mdc-paginator-outer-container {
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 12px;
}
.mat-mdc-paginator-container {
  min-height: 66.5px !important;
}

.mat-mdc-paginator-page-size-select {
  width: 60.5px !important;
}
.mdc-text-field--outlined {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.mat-mdc-paginator {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.54);
}

/***/
.mdc-notched-outline {
  background-color: white;
  z-index: 0 !important;
  border-radius: 0.25rem !important;
}



/* Mat slide toggle*/
.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: rgb(59 130 246);
  --mdc-switch-selected-handle-color: rgb(59 130 246);
  --mdc-switch-selected-hover-state-layer-color: rgb(59 130 246);
  --mdc-switch-selected-pressed-state-layer-color: rgb(59 130 246);
  --mdc-switch-selected-focus-handle-color: rgb(37 99 235);
  --mdc-switch-selected-hover-handle-color: rgb(37 99 235);
  --mdc-switch-selected-pressed-handle-color: rgb(37 99 235);
  --mdc-switch-selected-focus-track-color: rgb(191 219 254);
  --mdc-switch-selected-hover-track-color: rgb(191 219 254);
  --mdc-switch-selected-pressed-track-color: rgb(191 219 254);
  --mdc-switch-selected-track-color: rgb(191 219 254);
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);

  --mdc-switch-unselected-handle-color: #FAFAFA;
  --mdc-switch-unselected-focus-handle-color: rgb(248 250 252);
  --mdc-switch-unselected-hover-handle-color: rgb(248 250 252);
  --mdc-switch-unselected-pressed-handle-color: rgb(248 250 252);

  // --mdc-switch-disabled-selected-handle-color: #424242;
  // --mdc-switch-disabled-selected-icon-color: #fff;
  // --mdc-switch-disabled-selected-track-color: #424242;
  // --mdc-switch-disabled-unselected-handle-color: #424242;
  // --mdc-switch-disabled-unselected-icon-color: #fff;
  // --mdc-switch-disabled-unselected-track-color: #424242;
}

html {
  --mdc-outlined-text-field-outline-color: rgb(229, 231, 235);
  --mdc-filled-text-field-container-color: white;
}

.mdc-text-field--outlined {
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}


/* ngx-mat-file-input */
.import-group .mat-mdc-form-field-infix {
  max-height: 56px !important;
}
