
/* This injects Tailwind's base styles and any base styles registered by plugins. */
@use "tailwindcss/base";
@use "tailwindcss/components";
@use "tailwindcss/utilities";

// import custom styles
@use "highlight";

@use "../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@use "../../node_modules/ngx-popperjs/scss/theme-white.scss";
@use "../../node_modules/@fortawesome/fontawesome-pro/css/all.css";


body {
  font-size: 14px !important;
  font-family: Roboto, Helvetica Neue, sans-serif;
}

.btn {
  font-size: 13px;
}

.gridFilter {
  text-align: right;
  margin-top: 5px;
}

.gridFilter i {
  font-size: 18px;
  margin-left: 5px;
}

.title {
  margin-left: -15px;
}

.exportingData {
  color: #666;
  font-size: 30px;
  text-align: center;
}

.mb10 {
  margin-bottom: 10px;
}

pre {
  background-color: #1E1E1E;
  padding: 15px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.error-snackbar {
  background-color: rgb(172, 68, 68);
}

.error-snackbar .mat-mdc-button {
  color: #fff;
}

.stepBody h5 {
  margin-bottom: 0px;
}

.stepBody h5:not(:first-of-type) {
  margin-top: 10px;
}

.fab {
  position: fixed !important;
  right: 50px;
  bottom: 30px;
}

.currencyNegative {
  color: #e60000;
}

.currencyPositive {
  color: #2e7d32;
}

@keyframes button-spinner {
  to {
    transform: rotate(360deg);
  }
}

.button-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
mat-radio-button label {
  margin-bottom: 0px !important;
}

.matSnackBar-error {
  background-color: rgb(172, 68, 68) !important;
}

.matSnackBar-error .mat-mdc-button {
  color: #fff;
}

sn-total-display .mat-mdc-card {
  margin-bottom: 20px;
}

.header-less-tabs .mat-mdc-tab-header {
  display: none;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 10000;
}

mat-toolbar {
  position: fixed;
}

mat-sidenav-container {
  position: absolute !important;
  top: 60px !important;
  bottom: 0px !important;
  left: 0 !important;
  right: 0 !important;
}

.shipment-review {
  margin-top: 10px;
}

.pac-container {
  z-index: 100000;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
mat-tab-header {
  background-color: white;
}

.ngx-mat-timepicker form .table .tbody tr td .mat-mdc-form-field {
  width: 40px !important;
  max-width: 40px !important;
  text-align: center;
}

.link {
  @apply font-medium text-blue-400 cursor-pointer hover:text-blue-500;
}

.fav {
  @apply text-yellow-300;
}

.tailwind-tab {
  @apply cursor-pointer border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm;
}

.tailwind-tab-selected {
  @apply border-blue-500 text-blue-600;
}

.mat-form-field-infix {
  width: unset!important;
}

.grid-filter-panel {
  @apply w-full md:w-1/2;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-disabled .mat-form-field-underline {
  border-bottom: 1px solid #8e8e8e;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-wrapper {
  width: 100%;
}

.filter-button {
  @apply z-30 fixed flex items-center justify-center left-0 w-10 h-10 shadow-lg rounded-r-lg cursor-pointer bg-blue-400 bg-opacity-70 print:hidden top-1/4;
}

.cdk-drag-handle {
  cursor: move;
}

.requirements {
  font-size: 13px;
  text-align: left;
}

// Alternate CSS Spinner
.loading-ellipsis {
  position: relative;
  width: 80px;
  height: 80px;
}
.loading-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  @apply bg-blue-400;
}
.loading-ellipsis div:nth-child(1) {
  left: 8px;
  animation: loading-ellipsis1 0.6s infinite;
}
.loading-ellipsis div:nth-child(2) {
  left: 8px;
  animation: loading-ellipsis2 0.6s infinite;
}
.loading-ellipsis div:nth-child(3) {
  left: 32px;
  animation: loading-ellipsis2 0.6s infinite;
}
.loading-ellipsis div:nth-child(4) {
  left: 56px;
  animation: loading-ellipsis3 0.6s infinite;
}
@keyframes loading-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loading-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.mat-mdc-tooltip {
  font-size: 12px!important;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  @apply bg-blue-200;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  @apply bg-blue-500;
}

.mat-mdc-slide-toggle.mat-checked .mat-ripple-element {
  @apply bg-blue-500;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  @apply bg-blue-500;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-ripple .mat-ripple-element {
  @apply bg-blue-500;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  @apply bg-blue-500;
}

.shipment-items-header th:last-of-type {
  padding-right: 5px;
}

.notification-emails-container .mat-form-field-infix {
  padding-top: 5px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  place-self: center;
}

pre {
  color: white;
}

.multiline-mat-option.mat-mdc-option {
  height: auto;
  white-space: normal;
  line-height: normal;
}

.mat-mdc-form-field-hint {
  @apply text-xs;
}

.mat-mdc-snack-bar-action > .mdc-button__label {
  @apply text-white;
}

.shipment-stop-location-hover:hover {
  color: rgb(37, 99, 235) !important
}

.shipment-stop-location-hover:hover p{
  color: rgb(37, 99, 235) !important
}

.shipment-stop-location p {
  color: rgb(107, 114, 128)
}


.freight-popper > div.ngxp__container {
  width: 400px !important;
  max-width: none !important
}

.quote-details-popper > div.ngxp__container {
  max-width: none !important
}

.load-option {
  @apply inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium;
}

.load-option + .load-67108864, //interline
.load-option + .load-536870912, //reweigh
.load-option + .load-1073741824, // reclass
.load-option + .load-2147483648 { //redimension
  @apply bg-red-50 text-red-700 ring-1 ring-inset ring-red-600/10;
}

.load-67108864 > button.deleteButton, //interline
.load-536870912 > button.deleteButton, //reweigh
.load-1073741824 > button.deleteButton, // reclass
.load-2147483648 > button.deleteButton { //redimension
  @apply text-red-400 hover:text-red-500 focus:bg-red-500;

}

button.deleteButton {
  @apply text-blue-400 hover:text-blue-500 focus:bg-blue-500;
}

.rich-text-editor {
  ol, ul, menu {
    list-style: revert;
    margin: inherit;
    padding: inherit;
    padding-left: 1.5rem;
  }

  h1 {
    font-size: 26px;
    line-height: 36px;
  }

  h2 {
    font-size: 20px;
    line-height: 30px;
  }

  a {
    color: #06c
  }

  .jodit-wysiwyg a {
    text-decoration: underline;
  }

  .jodit-container:not(.jodit_inline) .jodit-wysiwyg {
    padding: 15px !important;
  }

  .rich-text-editor {
    line-height: 1.4;
  }
}

sn-content-body a {
  @apply font-medium text-blue-400 cursor-pointer hover:text-blue-500;
}

#smartInboxQuoteCount .mat-badge-content {
  @apply bg-yellow-500 text-gray-900;
}

.small-badge-text span {
  font-size: 0.6rem !important;
}

mat-checkbox input[type=checkbox][disabled]{
  opacity: 0;
}

mat-checkbox input[type=radio][disabled]{
  opacity: 0;
}

.folder-item .cdk-drag-placeholder {
  display: none !important;
}

content-viewer img {
  width: unset;
  height: unset;
}
