.mat-mdc-cell, .mat-mdc-header-cell {
	@apply px-2 #{!important};
}

.mat-sort-header-sorted,
.mat-sort-header-sorted>.mat-sort-header-arrow {
	@apply text-blue-400 font-semibold;
}

tr.mat-mdc-row {
    @apply divide-x;
}

th.mat-mdc-header-cell .mat-sort-header-container.mat-sort-header-sorted .mat-sort-header-arrow {
	opacity: 1 !important;
	transform: translateY(0) !important;
	@apply border-gray-300;
}

.mat-mdc-row:nth-child(even) {
	@apply bg-gray-50 #{!important};
}

.mat-mdc-header-row {
	@apply bg-gray-100 #{!important};
}

.mat-mdc-row:hover {
	@apply bg-blue-50 #{!important};
}

th.mat-mdc-header-cell {
    @apply text-gray-500 uppercase tracking-wider font-medium;
}

td .mat-checkbox-layout,
th .mat-checkbox-layout {
	margin-bottom: 0px!important;
}

.mat-mdc-table {
	width: 100%;
	@apply border-separate;
}

.mat-mdc-footer-cell {
	font-weight: bold;
	font-size: 16px;
}

mat-paginator mat-form-field {
	margin-top: 0px!important;
}

th {
	z-index: 15!important;
}

table, tbody {
	height: fit-content;
}

.mat-sort-header-arrow.ng-trigger.ng-trigger-arrowPosition {
    opacity: 0;
}

.mat-sort-header-pointer-left.ng-trigger.ng-trigger-leftPointer{
    transform: rotate(-45deg);
}

.mat-sort-header-pointer-right.ng-trigger.ng-trigger-rightPointer{
    transform: rotate(45deg);
}
